import { useState, useEffect } from "react";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import { IconButton, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import CallIcon from "@mui/icons-material/Call";

import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { keyframes, styled } from "@mui/system";

const textBlink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const BlinkingGradientText = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(90deg, #00FF00, #e03131)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  animation: `${textBlink} 1s infinite`,
  display: "inline-block",
}));

const useListClient = (handleClick, open) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [isMasterDataLoaded, setIsMasterDataLoaded] = useState(false);
  const [visibileClientColumns, setVisibileClientColumns] = useState({});

  const [allFilterList, setAllFilterList] = useState({
    medicalConditon: [],
    trainerList: [],
    dietitianList: [],
    saleRole: [],
  });

  const convertUtcToLocalTime = (utc) => {
    let inputDate;
    let formattedDate;

    if (utc) {
      const utcTimestamp = utc;
      const dateInUTC = new Date(utcTimestamp);
      const localTime = dateInUTC.toLocaleString();
      inputDate = localTime.split(",");

      const [month, day, year] = inputDate[0].split("/");
      formattedDate = `${day.padStart(2, "0")}-${month}-${year}`;
    } else {
      return [];
    }

    return [formattedDate, inputDate];
  };

  const gender = [
    { value: "M", label: "M" },
    { value: "F", label: "F" },
    { value: "O", label: "O" },
  ];

  const activiySatus = [
    { value: "Active", label: "Active" },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];

  const progressStatus = [
    { value: "Overdue", label: "Overdue" },
    { value: "Urgent Attention", label: "Urgent Attention" },
    { value: "Up to date", label: "Up to date" },
  ];

  const programStatusList = [
    { id: "Premium", name: "Premium" },
    { id: "Free", name: "Free" },
    { id: "New Allocated", name: "New Allocated" },
    { id: "New Later", name: "New Later" },
    { id: "Renewed", name: "Renewed" },
    { id: "Tenure Ending", name: "Tenure Ending" },
    { id: "New Unallocated", name: "New Unallocated" },
  ];
  // -------- Add new operator "not contains" --------
  // Get the base operators
  const stringOperators = getGridStringOperators();
  // Find the "contains" operator
  const containsOperator = stringOperators.find(
    (operator) => operator.value === "contains"
  );

  // Create a "not contains" operator by cloning the "contains" operator and customizing it
  const notContainsOperator = { ...containsOperator };
  notContainsOperator.value = "notEquals";
  notContainsOperator.label = "notEquals";
  stringOperators.splice(1, 0, notContainsOperator);
  const [masterData, setMasterData] = useState({
    leadStages: [],
    leadDisposition: [],
    leadSubDisposition: [],
    leadNotInterested: [],
    users: [],
    sources: [],
    interactionCategories: [],
  });

  function getDisplayValueById(arr, id, fn) {
    try {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return fn(arr[i]);
          // arr[i][attrName];
        }
      }
    } catch (error) {
      let err = error;
    }
    return "-";
  }

  const clientColumnsList = [
    {
      field: "id",
      headerName: "Client ID",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params.row?.id ? (
          <span>
            {params.row?.id} &nbsp;
            {params.row?.upgrade_interest && <CallIcon />}
          </span>
        ) : (
          "-"
        ),
    },
    {
      field: "Name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row?.Name ? (
              <>
                <span className="mr-5">
                  <CheckBoxIcon
                    style={{
                      fontSize: "20px",
                      color: params.row.PaymentStatus.color,
                    }}
                  />
                </span>

                {params.row?.isNewClient && (
                  <BlinkingGradientText component="span">
                    New
                  </BlinkingGradientText>
                )}
                {params.row?.isRenewed && (
                  <BlinkingGradientText component="span">
                    Renewed
                  </BlinkingGradientText>
                )}
                <span> {params.row?.Name}</span>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },

    //PaymentStatus
    {
      field: "Phone",
      headerName: "Phone Number",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.Phone ? params.row?.Phone : "-"),
    },
    {
      field: "Email",
      headerName: "Email Id",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.Email ? params.row?.Email : "-"),
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      width: 150,
      valueOptions: gender.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      valueGetter: (params) => (params.row?.gender ? params.row?.gender : "-"),
    },
    {
      field: "age",
      headerName: "Age",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.age ? params.row?.age : "-"),
    },
    {
      field: "height",
      headerName: "Height",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.height ? params.row?.height : "-"),
    },
    {
      field: "current_weight",
      headerName: "Current Weight",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        params.row?.current_weight ? params.row?.current_weight : "-",
    },
    {
      field: "bmi",
      headerName: "BMI",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.bmi ? params.row?.bmi : "-"),
    },
    {
      field: "City",
      headerName: "City",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => (params.row?.City ? params.row?.City : "-"),
    },
    {
      field: "country",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        params.row?.country ? params.row?.country : "-",
      filterOperators: stringOperators,
    },
    {
      field: "form_filled_date",
      headerName: "Form Fill up date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.form_filled_date;
      },
      renderCell: (params) =>
        !!params.row.form_filled_date
          ? convertUtcToLocalTime(params.row.form_filled_date)[0]
          : "-",
    },
    {
      field: "form_submission_count",
      headerName: "Form Submissions",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        !!params.row.form_submission_count
          ? params.row.form_submission_count
          : "-",
    },
    {
      field: "fitness_goals",
      headerName: "Primary Goal",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        params.row?.fitness_goals ? params.row?.fitness_goals : "-",
    },
    {
      field: "social_media_handle_name",
      headerName: "Social Media Handler",
      headerClassName: "super-app-theme--header",
      width: "200",
      valueGetter: (params) =>
        params.row?.social_media_handle_name
          ? params.row?.social_media_handle_name
          : "-",
    },

    {
      field: "current_medical_condition",
      headerName: "Medical Condition",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      valueOptions: allFilterList?.medicalConditon.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),

      //
      renderCell: (params) => {
        return params.row?.current_medical_condition
          ? params.row?.current_medical_condition?.join?.()
          : "-";
      },
    },
    {
      field: "referral_code",
      headerName: "Referral",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) =>
        params.row?.referral_code ? params.row?.referral_code : "-",
    },
    {
      field: "sales_person",
      headerName: "Sales Person",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: allFilterList.saleRole.map((e) => {
        return {
          value: e.id,
          label:
            (e.firstName ? e.firstName : "") +
            " " +
            (e.lastName ? e.lastName : ""),
        };
      }),

      //in case of select type renderCell to be used

      renderCell: (params) =>
        params.row?.sales_person ? params.row?.sales_person : "-",
    },
    {
      field: "ClientDietitian",
      headerName: "Nutritionist",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown

      valueOptions: allFilterList?.dietitianList.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row.ClientDietitian) {
          let findCoach = allFilterList?.dietitianList.filter(
            (item) => item.value === obj.row.ClientDietitian
          )[0];

          return <span>{findCoach?.label}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "ClientTrainer",
      headerName: "Fitness Coach",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      valueOptions: allFilterList?.trainerList.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (obj) => {
        if (obj.row.ClientTrainer) {
          let findCoach = allFilterList?.trainerList.filter(
            (item) => item.value === obj.row.ClientTrainer
          )[0];

          return <span>{findCoach?.label}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "progressStatus",
      headerName: "Progress status",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      width: 150,
      valueOptions: progressStatus.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      valueGetter: (params) =>
        params.row?.progressStatus ? params.row.progressStatus : "-",
    },
    {
      field: "callStatus",
      headerName: "Call Status",
      headerClassName: "super-app-theme--header",
      type: "singleSelect",
      width: 150,
      valueOptions: activiySatus.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      valueGetter: (params) =>
        params.row?.callStatus ? params.row.callStatus : "-",
    },
    {
      field: "next_followup_date",
      headerName: "Next Followup Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.next_followup_date;
      },
      renderCell: (params) =>
        params.row?.next_followup_date
          ? convertUtcToLocalTime(params.row.next_followup_date)[0]
          : "-",
    },
    {
      field: "preferred_language",
      headerName: "Preferred Language",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params.row?.preferred_language ? params.row?.preferred_language : "-",
    },
    {
      field: "source",
      headerName: "Account",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      // options for select dropdown
      valueOptions: masterData.sources.map((e) => {
        return { value: e.id, label: e.Source };
      }),
      //in case of select type renderCell to be used

      renderCell: (params) => (params.row?.source ? params.row?.source : "-"),

      // renderCell: (obj) => {
      //   if (obj.row?.source) {
      //     let val = getDisplayValueById(
      //       masterData.sources,
      //       obj.row?.source,
      //       (e) => e.Source
      //     );
      //     return <span>{val}</span>;
      //   } else {
      //     return "-";
      //   }
      // },
    },
    {
      field: "accountId",
      headerName: "Account Id",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (params) =>
        params.row?.accountId ? params.row?.accountId : "-",
    },
    {
      field: "call_category",
      headerName: "Call Category",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "singleSelect",
      //========================================
      valueOptions: masterData.interactionCategories.map((e) => {
        return { value: e.id, label: e.name };
      }),
      //in case of select type renderCell to be used
      renderCell: (obj) => {
        if (obj.row?.call_category) {
          let val = getDisplayValueById(
            masterData.interactionCategories,
            obj.row?.call_category,
            (e) => e.name
          );
          return <span>{val}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      field: "programStatus",
      headerName: "Program Status",
      headerClassName: "super-app-theme--header",
      width: 180,
      type: "singleSelect",
      valueOptions: programStatusList.map((e) => {
        return { value: e.id, label: e.name };
      }),
      renderCell: (obj) => {
        if (obj.row.programStatus) {
          return (
            <div
              style={{
                backgroundColor:
                  obj.row.programStatus === "Premium"
                    ? "#69db7c"
                    : obj.row.programStatus === "Renewal premium"
                    ? "#40c057"
                    : "",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              <span>{obj.row.programStatus}</span>
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    // {
    //   field: "activity_status",
    //   headerName: "Activity Status",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   type: "singleSelect",
    //   valueOptions: activiySatus.map((e) => {
    //     return {
    //       value: e.value,
    //       label: e.label,
    //     };
    //   }),
    //   valueGetter: (params) => {
    //     if (params.row.activeStatus !== null) {
    //       return params.row.activeStatus;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    // tenure_end_date
    // update_date
    {
      field: "tenureEndDate",
      headerName: "Tenure End Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.tenureEndDate;
      },
      renderCell: (params) =>
        params.row?.tenureEndDate
          ? convertUtcToLocalTime(params.row.tenureEndDate)[0]
          : "-",
    },

    {
      field: "updatedAt",
      headerName: "Update Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.updatedAt;
      },
      renderCell: (params) =>
        params.row?.updatedAt
          ? convertUtcToLocalTime(params.row.updatedAt)[0]
          : "-",
    },

    // {
    //   field: "update_by",
    //   headerName: "Updated By",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   valueGetter: (params) => {
    //     if (params.row.LeadID && params.row.LeadID.updated_by_details) {
    //       return params.row.LeadID.updated_by_details.Username;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },

    {
      field: "createdAt",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.createdAt;
      },
      renderCell: (params) =>
        params.row?.createdAt
          ? convertUtcToLocalTime(params.row.updatedAt)[0]
          : "-",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 150,
      hideable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="delete"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, params)}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  const getDropdown = async () => {
    const filterList = await axiosFetch({
      url: `/getTrainersNutritionMedicalForFilterDropdown`,
      method: "get",
    });

    const assignList = await axiosFetch({
      url: "/getSalesAssignUsers",
      method: "get",
    }).then((res) => res.data);

    axiosFetch({
      url: `/lead-screen-master-data`,
      method: "get",
    }).then((res) => {
      setIsMasterDataLoaded(true);
      setMasterData(res.data);
    });

    const getColumnList = await axiosFetch({
      url: `getListViewByUserIdAndScreen/${userId}/2`,
      method: "get",
    }).then((res) => res.data);

    setVisibileClientColumns(getColumnList?.fieldList);
    setAllFilterList({ ...filterList?.data, saleRole: assignList });
  };

  useEffect(() => {
    getDropdown();
  }, []);

  return {
    visibileClientColumns,
    clientColumnsList,
    allFilterList,
    masterData,
  };
};

export default useListClient;

// let defaultVisibileColumns = {
//   id: true,
//   name: true,
//   contact_number: true,
//   email_id: true,
//   gender: true,
//   account_id: true,
//   ClientDietitianInfo: true,
//   ClientTrainerInfo: true,
//   tenure_end_date: true,

//   age: false,
//   height: false,
//   current_weight: false,
//   bmi: false,
//   city: false,
//   country: false,
//   vmax_fit_goal: false,
//   social_media_handle_name: false,
//   current_medical_condition: false,
//   referral_code: false,
//   assigned_to_user: false,
//   Status: false,
//   call_status: false,
//   next_followup_date: false,
//   preferred_language: false,
//   account_id_code: false,
//   call_category: false,
//   program_status: false,
//   update_date: false,
//   created_date: false,
// };
